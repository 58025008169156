<template>
    <div class="col-sm-12">
      <apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
    </div>
</template>
<script>
     function chartOptions(){
         return {
            chart: {
                type: 'bar',
                height: 'auto',
                animations: {
                    enabled: true,
                }
            },
            colors: this.Colors,
            xaxis: {
                categories: this.Categories,
                labels: {
                    style: {
                        colors: this.LabelColor,
                        fontSize: '10px'
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                }
             },
            plotOptions: {
              bar: {
                distributed: true,
              }
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val) {
                        return val+'%';
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val+'%';
                },
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                }
            },
        }    
     }

     export default {
         name:'SimpleBarPercent',
          props: {
             Series: {
                 type: Array,
                 require: true,
                 default: () => [],
             },
             Categories:{
                 type: Array,
                 require: true,
                 default: () => [],
             },
             Colors:{
                type: Array,
                require: false,
                default: () => [],
            },
            Width:{
                type: String,
                require: true,
                default: 'auto',
            },
            Height:{
                type: String,
                require: true,
                default: 'auto',
            },
            LabelColor:{
                type: String,
                require: false,
                default: '',
            },
         },
         methods:{
         },
         computed:{
             chartOptions
         },
     }
</script>