<template>
    <div style="width: 100%">
        <div class="d-flex align-content-center position-relative">
            <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                <a
                    href="#" 
                    @click.prevent="disminuirCarrusel"
                    class="arrow left"
                ></a>
            </div>
            <div class="flex-grow-1" style="height:100%">
                <CCollapse :show="carruselTab === 1"  class="p-arrows-carousel" >
                    <ResumenDiario 
                        :OperationDays="OperationDays"
                    >
                    </ResumenDiario>
                </CCollapse>
                <CCollapse :show="carruselTab === 2"  class="p-arrows-carousel" >
                    <resumen-operacion-mph :OperationSumary="OperationSumary"></resumen-operacion-mph>
                </CCollapse>
                <CCollapse :show="carruselTab === 3"  class="p-arrows-carousel" >
                    <resumen-operacion-ton-embarcada :OperationSumary="OperationSumary"></resumen-operacion-ton-embarcada>
                </CCollapse>
            </div>
            <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                <a
                    href="#" 
                    @click.prevent="aumentarCarrusel"
                    class="arrow right"
                ></a>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import ResumenDiario from "./ResumenDiario";
    import ResumenOperacionMph from "./ResumenOperacionMph";
    import ResumenOperacionTonEmbarcada from "./ResumenOperacionTonEmbarcada";
    //data
    function data() {
        return {
            interval: null,
            OperationDays: [],
            OperationSumary: [],
        }
    }
    //methods
    function getTabDailySummary() {
        this.$store.state.situacionOperativaHistorico.Loading = true;
        this.$http.ejecutar('GET', 'VisitOperationalSituationTabDailySummary', {VisitId: this.VisitId})
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                    this.OperationDays = List[0]?.DailySummaryJson[0]?.OperationDaysJson ? 
                        List[0].DailySummaryJson[0].OperationDaysJson : [];
                    this.OperationSumary = List[0]?.DailySummaryJson[0]?.OperationSumaryJson ? 
                        List[0].DailySummaryJson[0].OperationSumaryJson : [];
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaHistorico.Loading = false;
            });
    }
    function disminuirCarrusel(){
        if(this.$store.state.situacionOperativaHistorico.carruselTab !== 1){
            this.$store.state.situacionOperativaHistorico.carruselTab = this.$store.state.situacionOperativaHistorico.carruselTab - 1;
        }
    }
    function aumentarCarrusel(){
        if(this.$store.state.situacionOperativaHistorico.carruselTab < 3){
            this.$store.state.situacionOperativaHistorico.carruselTab = this.$store.state.situacionOperativaHistorico.carruselTab + 1;
        }
    }
    //computed
    function minCarrusel(){
        return this.$store.state.situacionOperativaHistorico.carruselTab > 1
    }
    function maxCarrusel(){
        return this.$store.state.situacionOperativaHistorico.carruselTab < 3
    }

    export default {
        name:'indesSummary',
        data,
        methods:{
            getTabDailySummary,
            disminuirCarrusel,
            aumentarCarrusel,
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if(this.$store.state.situacionOperativaHistorico.carruselTab < 3){
                        this.$store.state.situacionOperativaHistorico.carruselTab = this.$store.state.situacionOperativaHistorico.carruselTab + 1;
                    }else{
                        this.$store.state.situacionOperativaHistorico.carruselTab = 1;
                    } 
                }, 30000);
            }
        },
        beforeDestroy(){
            clearInterval(this.interval);
            this.interval = null;
        },
        computed:{
            minCarrusel,
            maxCarrusel,
            ...mapState({
                carrusel: state => state.situacionOperativaHistorico.carrusel,
                carruselTab : state=> state.situacionOperativaHistorico.carruselTab,
                tabsOperationes: state=> state.situacionOperativaHistorico.tabsOperationes,
                FgBulk: state=> state.situacionOperativaHistorico.FgBulk,
                VisitId: state=> state.situacionOperativaHistorico.VisitId,
            })
        },
        components:{
            ResumenDiario,
            ResumenOperacionMph,
            ResumenOperacionTonEmbarcada,
        },
        watch:{
            tabsOperationes(newValue,OldValue){
                if(OldValue === 8 && this.FgBulk){
                    this.OperationDays = [];
                    this.OperationSumary = [];
                    this.$store.state.situacionOperativaHistorico.carruselTab = 1;
                    clearInterval(this.interval);
                    this.interval = null;
                }else if(newValue === 8 && this.FgBulk){
                    this.getTabDailySummary();
                    this.$nextTick(async () => {
                        if(this.carrusel){
                            this._setInterval();
                        }
                    });
                }
            },
            carrusel(newValue){
                if(newValue){
                    if(this.tabsOperationes === 8 && this.FgBulk){
                        this.$nextTick(async () => {
                            this._setInterval();
                        });
                    }
                }else{
                    clearInterval(this.interval);
                    this.interval = null;
                }
            }
        }
    }
</script>

<style lang="scss" >
    .cardResumen{
        min-width: 0;
        margin-bottom: 1.5rem;
        border: 1px solid;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-left-color: currentcolor;
        background-color: #203864;
        border-color: #203864;
    }
    .cardHeaderResumenPersonalizado{
        padding: 0.75rem 1.25rem;
    }
</style>