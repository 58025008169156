//Data:
function data() {
  return {
    baysJson: [],
  }
}

//Meodos:
function getDataFromService(id) {
  this.$http.ejecutar('GET', 'VesselTransversalView-by-vesselA', { VesselId: id })
  .then(response => {
    this.baysJson = response.data.data || [];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
function generateTiersArray(tierFrom, tierTo, bayCode) {
  if(!bayCode) return [];

  let arr = [],
    end = Number.parseInt(tierFrom, 10),
    start = Number.parseInt(tierTo, 10);

  for (let index = start; index >= end; index--) {
    if (Math.abs(index % 2) === 0) {
      arr.push({
        BayCode: bayCode,
        Tier: index < 10 ? `0${index}` : `${index}`,
        Code: `${bayCode}-${index}`,
      });
    }
  }
  return arr;
}

//Computeds
function formatedToDeck() {
  return this.baysJson.map((bay) => {
      if(bay.BayCode && bay.FgBay) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: bay.PositionJson[0].ToDeckJson.length == 1 ? this.generateTiersArray(
            bay.PositionJson[0].ToDeckJson[0].TierToDeckFrom,
            bay.PositionJson[0].ToDeckJson[0].TierToDeckTo,
            bay.BayCode,
          ) : [],
        });
      } else if(!bay.BayCode && bay.FgCrane) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: [],
        });
      }
  });
}
function formatedUnderDeck() {
  return this.baysJson.map((bay) => {
      if(bay.BayCode && bay.FgBay) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          Tiers: bay.PositionJson[0].UnderDeckJson.length == 1 ? this.generateTiersArray(
            bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckFrom,
            bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckTo,
            bay.BayCode,
          ) : [],
        });
      } else if(!bay.BayCode && bay.FgCrane) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          Tiers: [],
        });
      }
  });
}

export default {
  data,
  methods: {
    getDataFromService,
    generateTiersArray,
  },
  computed: {
    formatedToDeck,
    formatedUnderDeck,
  }
}