<template>
    <div style="width: 100%">
        <div class="d-flex align-content-center position-relative">
            <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                <a
                    href="#" 
                    @click.prevent="disminuirCarrusel"
                    class="arrow left"
                ></a>
            </div>
            <div class="flex-grow-1">
                <div class="card" style="width: 100%;border-color: #4d5a70 !important;height:100%">
                    <div
                        class="card-header"
                        :style="
                            'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                        "
                    >
                        <span>{{ $t('label.totalMovemnsPerDay') }}</span>
                    </div>
                    <div class="card-body" v-if="dataInformationArray.length !== 0">
                        <div v-if="dataInformationArray[carruselTab-1] !== undefined" style="height:350px">
                            <reactive-chart 
                                height="350px" 
                                :title="dataInformationArray[carruselTab-1].title" 
                                :minX="dataInformationArray[carruselTab-1].minX" 
                                type="bar" 
                                :labels="dataInformationArray[carruselTab-1].categories"
                                :datasets="dataInformationArray[carruselTab-1].series" 
                                :options="dataInformationArray[carruselTab-1].options">
                            </reactive-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                <a
                    href="#" 
                    @click.prevent="aumentarCarrusel"
                    class="arrow right"
                ></a>
            </div>
        </div>
    </div>
</template>

<script>
    import ReactiveChart from "./ReactiveChart";
    import { mapState } from 'vuex';
    //data
    function data() {
        return {
            interval: null,
            ProductivityByTurn: [],
        }
    }
    //methods
    function getTabProductivity() {
        this.$store.state.situacionOperativaHistorico.Loading = true;
        this.$http.ejecutar('GET', 'VisitOperationalSituationTabProductivityByTurn', {VisitId: this.VisitId})
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                    this.ProductivityByTurn = List[0]?.BulkCargoResultJson[0]?.ProductivityByTurnJson ?
                        List[0].BulkCargoResultJson[0].ProductivityByTurnJson : [];
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaHistorico.Loading = false;
            });
    }
    function disminuirCarrusel(){
        if(this.$store.state.situacionOperativaHistorico.carruselTab !== 1){
            this.$store.state.situacionOperativaHistorico.carruselTab = this.$store.state.situacionOperativaHistorico.carruselTab - 1;
        }
    }
    function aumentarCarrusel(){
        if(this.$store.state.situacionOperativaHistorico.carruselTab !== this.maxForCarruselLines){
            this.$store.state.situacionOperativaHistorico.carruselTab = this.$store.state.situacionOperativaHistorico.carruselTab + 1;
        }
    }
    //computed
    function maxForCarruselLines(){
        let max = 1;
        let Data = this.ProductivityByTurn;
        let maxOperation = (this.FgContainerShip ? 5 : this.FgGeneralCargo ? 6 : 7);
        if(Data.length !== 0){
            if(this.tabsOperationes === maxOperation){
                return Data.length;
            }
        }
        return max;
    }
    function minCarrusel(){
        return this.$store.state.situacionOperativaHistorico.carruselTab > 1
    }
    function maxCarrusel(){
        return this.$store.state.situacionOperativaHistorico.carruselTab < this.maxForCarruselLines
    }
    function dataInformationArray(){
        let data = [];
        let maxOperation = (this.FgContainerShip ? 5 : this.FgGeneralCargo ? 6 : 7);
        if(this.tabsOperationes === maxOperation){
            let DataInf = this.ProductivityByTurn;
            if(DataInf.length !== 0){
                let i = 1;
                DataInf.map(async(item) => {
                    let categories = this.$i18n.locale == 'es' ? item.corteEsJson : item.corteEnJson;
                    let series = [];
                    var array1 = item.colorJson;
                    var array2 = ["#E81717"];
                    var arrayColor = array1.concat(array2);
                    let valueMin = 0.5;
                    item.craneTurnJson.map(async(item2,index) => {
                        let name = "";
                        if(item2.name == undefined ){
                            name = this.$i18n.locale == 'es' ? "Grua sin nombre" : "Grua sin nombre";
                        }else{
                            name = this.$i18n.locale == 'es' ? item2.name[0].Es : item2.name[0].En;
                        }
                        for (let x = 0; x < item2.data.length; x++) {
                            if(item2.data[x] > valueMin){
                                valueMin = item2.data[x];
                            }
                        }
                        series.push({
                            label : name,
                            data : item2.data,
                            backgroundColor: arrayColor[index],
                        })
                    });

                    if(valueMin > 0.5){
                        valueMin = valueMin + 1;
                    }else{
                        valueMin = 8;
                    }
                    let categoriesHours = [];
                    categories.map(async(item2,index) => {
                        categoriesHours.push(item2+' ( '+item.hourRange[index]+' )');
                    })
    
                    data.push({
                        index: i,
                        title: item.DateTransaction,
                        hourRange: item.hourRange,
                        minX: valueMin,
                        categories: categoriesHours,
                        series: series,
                    });
                    i++;
                })
            }
        }
        return data;
    }
    export default {
        name:'ProductivityForTime',
        data,
        props: {
            ProductivityByTurnJson: {
                type: Array,
                default: () => [],
            }
        },
        methods:{
            getTabProductivity,
            disminuirCarrusel,
            aumentarCarrusel,
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if(this.$store.state.situacionOperativaHistorico.carruselTab !== this.maxForCarruselLines){
                        this.$store.state.situacionOperativaHistorico.carruselTab = this.$store.state.situacionOperativaHistorico.carruselTab + 1;
                    }else{
                        this.$store.state.situacionOperativaHistorico.carruselTab = 1;
                    }   
                }, 30000);
            }
        },
        beforeDestroy(){
            clearInterval(this.interval);
            this.interval = null;
        },
        computed:{
            maxForCarruselLines,
            minCarrusel,
            maxCarrusel,
            dataInformationArray,
            ...mapState({
                FgGeneralCargo: state => state.situacionOperativaHistorico.FgGeneralCargo,
                FgContainerShip: state => state.situacionOperativaHistorico.FgContainerShip,
                FgBulk: state => state.situacionOperativaHistorico.FgBulk,
                carruselTab : state=> state.situacionOperativaHistorico.carruselTab,
                tabsOperationes: state=> state.situacionOperativaHistorico.tabsOperationes,
                carrusel: state => state.situacionOperativaHistorico.carrusel,
                VisitId: state => state.situacionOperativaHistorico.VisitId,
            })
        },
        watch:{
            tabsOperationes(newValue,OldValue){
                let maxOperation = (this.FgContainerShip ? 5 : this.FgGeneralCargo ? 6 : 7);
                if(OldValue === maxOperation){
                    this.ProductivityByTurn = [];
                    this.$store.state.situacionOperativaHistorico.carruselTab = 1;
                    clearInterval(this.interval);
                    this.interval = null;
                }else if(newValue === maxOperation){
                    if (this.FgBulk) {
                        this.getTabProductivity();
                    } else {
                        this.ProductivityByTurn = this.ProductivityByTurnJson ? this.ProductivityByTurnJson : []
                    }
                    this.$nextTick(async () => {
                        if(this.carrusel){
                            await this._setInterval();
                        }
                    });
                }
            },
            carrusel(newValue){
                let maxOperation = (this.FgContainerShip ? 5 : this.FgGeneralCargo ? 6 : 7);
                if(this.tabsOperationes === maxOperation){
                    if(newValue){
                        this.$nextTick(async () => {
                            this._setInterval();
                        });
                    }else{
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                }
            }
        },
        components:{
            ReactiveChart
        }
    }
</script>